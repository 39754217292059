var urlBase = 'https://mapa.ecommerce.poczta-polska.pl/widget/',
  apiUrl = urlBase + 'pickuppoints/json',
  imagePath = urlBase + 'images/',
  version = '016',
  minorVersion = '002',
  availableTypes = [
    'POCZTA',
    'ORLEN',
    'AUTOMAT_POCZTOWY',
    'AUTOMAT_POCZTEX',
    'RUCH',
    'ZABKA',
    'FRESHMARKET',
    'AUTOMAT_BIEDRONKA',
    'AUTOMAT_CARREFOUR',
    'AUTOMAT_PLACOWKA',
    'AUTOMAT_SPOLEM',
    'SKRZYNKA_POCZTOWA',
    'AUTOMAT_LEWIATAN',
    'LEWIATAN',
    'ABC',
    'DELIKATESY_CENTRUM',
    'BIEDRONKA',
    'KAUFLAND',
    'GROSZEK',
    'ARHELAN',
    'AUTOMAT_POINTPACK'
  ],
  availableTypesObj = [
    { type: 'POCZTA', title: 'Placówka Pocztowa' },
    { type: 'ORLEN', title: 'Stacja ORLEN' },
    { type: 'AUTOMAT_POCZTOWY', title: 'Automat - Poczta' },
    { type: 'AUTOMAT_POCZTEX', title: 'Automat - Pocztex' },
    { type: 'RUCH', title: 'Kiosk/Salonik RUCH' },
    { type: 'ZABKA', title: 'Sklep Żabka' },
    { type: 'FRESHMARKET', title: 'Sklep Freshmarket' },
    { type: 'AUTOMAT_BIEDRONKA', title: 'Automat - Biedronka' },
    { type: 'AUTOMAT_PLACOWKA', title: 'Automat - Poczta' },
    { type: 'AUTOMAT_CARREFOUR', title: 'Automat - Carrefour' },
    { type: 'AUTOMAT_SPOLEM', title: 'Automat - Społem' },
    { type: 'SKRZYNKA_POCZTOWA', title: 'Skrzynka pocztowa' },
    { type: 'AUTOMAT_LEWIATAN', title: 'Automat - Lewiatan' },
    { type: 'LEWIATAN', title: 'Sklep Lewiatan' },
    { type: 'ABC', title: 'Sklep ABC' },
    { type: 'DELIKATESY_CENTRUM', title: 'Delikatesy Centrum' },
    { type: 'BIEDRONKA', title: 'Sklep Biedronka' },
    { type: 'KAUFLAND', title: 'Sklep Biedronka' },
    { type: 'GROSZEK', title: 'Sklep Groszek' },
    { type: 'ARHELAN', title: 'Sklep Arhelan' },
    { type: 'AUTOMAT_POINTPACK', title: 'Automat - Pointpack' }
  ];
!(function (p) {
  var c,
    m,
    y = [],
    u = '',
    A = !1,
    g = void 0,
    w = !1,
    f = {};
  'undefined' == typeof containerHeight
    ? (p.containerHeight = 600)
    : (p.containerHeight = containerHeight),
    (p.appendToBody = function () {
      var e = document.createElement('div');
      (e.id = 'overlay'),
        e.setAttribute(
          'style',
          'display:none; z-index: 1050; -webkit-overflow-scrolling: touch; outline: 0; position: absolute; top: 0; right: 0; bottom: 0; left: 0'
        );
      var t =
        '<div id="overlay-window" style="max-width: 1014px; width: 98%; margin: 5% auto 0; position: fixed; left: 0; right: 0; background-color: #fff;border: 1px solid rgba(0, 0, 0, 0.2);border-radius: 6px;box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);background-clip: padding-box;outline: 0;z-index:1050;">';
      (t +=
        void 0 === version
          ? '<iframe id = "pp-widget-iframe" name="pp-widget-iframe" style="max-width: 1014px; width: 100%;height: ' +
            p.containerHeight +
            'px;border: 0;overflow-y: hidden; margin: 0; display: block;" class="pp-widget-iframe" src="' +
            urlBase +
            'map-widget.html" scrolling="no" seamless="seamless" allow="geolocation">'
          : '<iframe id = "pp-widget-iframe" name="pp-widget-iframe" style="max-width: 1014px; width: 100%;height: ' +
            p.containerHeight +
            'px;border: 0;overflow-y: hidden; margin: 0; display: block;" class="pp-widget-iframe" src="' +
            urlBase +
            'map-widget-v' +
            version +
            '.html?v=' +
            minorVersion +
            '" scrolling="no" seamless="seamless" allow="geolocation">'),
        (t += '</iframe>'),
        (t += '</div>'),
        (t +=
          '<div id="overlay-backdrop" style="position: fixed;top: 0;right: 0;bottom: 0;left: 0;z-index: 1040;background-color: #000;opacity:0.5;"></div>'),
        (e.innerHTML = t),
        document.body.appendChild(e),
        (document.getElementById('overlay-backdrop').onclick = function () {
          p.closeOverlay();
        });
    }),
    (p.appendToElement = function (e) {
      var t = '';
      (t +=
        void 0 === version
          ? '<iframe id = "pp-widget-iframe" name="pp-widget-iframe" style="max-width: 1014px; width: 100%;height: ' +
            p.containerHeight +
            'px;border: 0;overflow-y: hidden;" class="pp-widget-iframe" src="' +
            urlBase +
            'map-widget.html" scrolling="no" seamless="seamless" allow="geolocation">'
          : '<iframe id = "pp-widget-iframe" name="pp-widget-iframe" style="max-width: 1014px; width: 100%;height: ' +
            p.containerHeight +
            'px;border: 0;overflow-y: hidden;" class="pp-widget-iframe" src="' +
            urlBase +
            'map-widget-v' +
            version +
            '.html?v=' +
            minorVersion +
            '" scrolling="no" seamless="seamless" allow="geolocation">'),
        (document.getElementById(e).innerHTML = t);
    }),
    (p.toggleMap = function (e, t, i, a, o, n, l, s) {
      var r,
        d = null,
        n = (d = 'object' == typeof e && e.callback ? e : d) ? d.embeddedElementId : n;
      n
        ? (r = document.getElementById(n)) && (p.appendToElement(n), (r.style.display = 'block'))
        : (p.appendToBody(), ((r = document.getElementById('overlay')).style.display = 'block')),
        (c = d ? d.callback : e),
        (y = d ? d.type : a),
        (A = d ? d.payOnPickup : t),
        (g = d ? d.payOnPickupMax : l),
        (w = d ? d.disableSelection : s),
        (u = d ? d.address : i),
        (f = (d ? d.options : o) || {}),
        (m = n ? document.getElementById(n) : null);
    }),
    (p.closeOverlay = function (e) {
      var t = m || document.getElementById('overlay');
      null != t &&
        ((t.style.display = 'none'), m ? (t.innerHTML = '') : t.parentNode.removeChild(t));
    }),
    (p.messageDispatcher = function (e) {
      var t, i, a;
      switch (
        void 0 !== (t = e) &&
        void 0 !== (t = t.data) &&
        null != t &&
        void 0 !== t.type &&
        ('initWidget' === t.type || 'sendPPData' === t.type || 'closeWidget' === t.type)
          ? t.type
          : ''
      ) {
        case 'initWidget':
          (i = document.getElementById('pp-widget-iframe')),
            (a = {
              dataType: 'PP_WIDGET_INIT',
              payOnPickup: A,
              payOnPickupMax: g,
              disableSelection: w,
              type: y,
              address: u,
              options: f
            }),
            i.contentWindow.postMessage(a, '*');
          break;
        case 'sendPPData':
          void 0 !== (a = e.data.value) && 'function' == typeof c && c(a), m || p.closeOverlay();
          break;
        case 'closeWidget':
          p.closeOverlay();
      }
    });
})((window.PPWidgetApp = window.PPWidgetApp || {})),
  ((window.PPWidgetApp = window.PPWidgetApp || {}).scriptsToLoad = [
    'https://use.fontawesome.com/releases/v5.2.0/css/all.css',
    'styles/leaflet.8da73df2.css',
    'styles/jquery.auto-complete.8a4174d7.css',
    'styles/pp-widget-embed-style.f4999039.css',
    {
      url: 'scripts/jquery.04cf960d.js',
      installed: function () {
        return !(void 0 === window.jQuery);
      }
    },
    'scripts/leaflet.b4200a3a.js',
    'scripts/ppwidget-standalone.045d1a8f.js',
    'scripts/jquery.auto-complete.7e2ae1cf.js',
    'scripts/jquery.geocomplete.15241e7a.js',
    'scripts/leaflet-markercluster.f2052841.js'
  ]),
  (function (i) {
    i.embedMap = function (e, t) {
      return (function (e) {
        for (var t = [], i = 0; i < e.length; i++) {
          var a = !1,
            o = e[i];
          'object' == typeof e[i]
            ? (e[i].installed && e[i].installed.call(this)) || ((a = !0), (o = e[i].url))
            : (a = !0),
            a &&
              t.push(
                (function (r) {
                  if (0 <= d.indexOf(r)) return Promise.resolve(r);
                  return (
                    c.push(r),
                    new Promise(function (e, t) {
                      var i = !1,
                        a = !1;
                      if (/(.css)$/i.test(r)) {
                        var o = document.createElement('link');
                        return (
                          (o.rel = 'stylesheet'),
                          (o.type = 'text/css'),
                          (o.href = r),
                          document.head.appendChild(o),
                          l(),
                          o
                        );
                      }
                      var o = document.getElementsByTagName('body')[0],
                        n = document.createElement('script');
                      return (
                        (n.type = 'text/javascript'),
                        (n.async = !1),
                        (n.onreadystatechage = function () {
                          i && l(), a && s();
                          var e = n.readyState;
                          'complete' === e ? l() : 'error' === e && s();
                        }),
                        n.addEventListener('load', l),
                        n.addEventListener('error', s),
                        (n.src = r),
                        o.appendChild(n),
                        n
                      );
                      function l() {
                        (i = !0), c.splice(c.indexOf(r), 1), d.push(r), e(r);
                      }
                      function s() {
                        (a = !0), c.splice(c.indexOf(r), 1), p.push(r), t(r);
                      }
                    })
                  );
                })(/^(f|ht)tps?:\/\//i.test(o) ? o : urlBase + o)
              );
        }
        return Promise.all(t);
      })(i.scriptsToLoad)
        .then(function () {
          (i.options = t || {}), i.initMap({ mapId: e });
        })
        .catch(function (e) {
          console.log('Error while embedding map: ' + e);
        });
    };
    var d = [],
      p = [],
      c = [];
  })((window.PPWidgetApp = window.PPWidgetApp || {})),
  (function (e) {
    document.addEventListener('DOMContentLoaded', function () {
      e.messageDispatcher &&
        (window.addEventListener
          ? window.addEventListener('message', e.messageDispatcher, !1)
          : window.attachEvent('onmessage', e.messageDispatcher));
    });
  })((window.PPWidgetApp = window.PPWidgetApp || {}));
